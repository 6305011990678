import { useTranslation } from 'react-i18next';

import { useResponsive } from 'hooks/useResponsive';
import Logotype from 'components/shared/Logotype/Logotype';
import SocialList from 'components/shared/SocialList/SocialList';
import DownloadAppButton from 'components/shared/DownloadAppButton/DownloadAppButton';
import { logoText, getFooterItems, socialLinks, downloadApp } from 'utils/constants';
import { bemCn } from 'utils/bem-cn';
import Loader from 'components/shared/loader/Loader';
import SupportIcon from 'components/shared/SupportIcon/SupportIcon';

import FooterNav from './components/FooterNav/FooterNav';
import PageFooterPayments from './components/PageFooterPayments';
import PageFooterRatingSites from './components/PageFooterRatingSites';
import PageFooterSportOrganisations from './components/PageFooterSportOrganisations';
import SiteSettings from './components/SiteSettings/SiteSettings';

import './PageFooter.scss';

type Props = {
  isAuthenticated: boolean;
  isAccountLoading: boolean;
};

const PageFooter = ({ isAuthenticated, isAccountLoading }: Props) => {
  const { t } = useTranslation();
  const b = bemCn('page-footer');
  const { Mobile, Desktop } = useResponsive();

  if (isAccountLoading) {
    return <div className="page-footer page-footer--loading"><Loader /></div>;
  }

  return (
    <footer className={b()}>
      <div className={b('wrapper')}>
        <div className={b('top-wrapper')}>
          <Mobile>
            {isAuthenticated ? (
              <div className={b('logo-support')}>
                <Logotype className={b('logo')} />
                {/* <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className={b('logo-support-link')}
                >
                  Support: 0711291291
                </a>
                <SupportIcon /> */}
              </div>
            ) : (
              <div className={b('logo-support')}>
                <Logotype className={b('logo')} />
                {/* <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className={b('logo-support-link')}
                >
                  Support: 0711291291
                </a> */}
              </div>
            )}
          </Mobile>

          <Desktop>
            <>
              <Logotype className={b('logo')} />
              {/* <div className={b('support')}>
                <p>Support</p>
                <a
                  href=""
                  target="_blank"
                  rel="noreferrer"
                  className={b('support-link')}
                >
                  0711291291
                </a>
              </div> */}
            </>
          </Desktop>

          {/* <Logotype className={b('logo')} /> */}
          <FooterNav className={b('nav')} navItems={getFooterItems()} />
          {/* <div className={b('download-app')}>
            <DownloadAppButton type='ios' link={downloadApp.ios} />
            <DownloadAppButton type='android' link={downloadApp.android} />
          </div>
          <PageFooterSportOrganisations b={b} />
        </div>

        <div className={b('center-wrapper')}>
          <PageFooterPayments b={b} />

          <div className={b('small-wrapper')}>
            <PageFooterRatingSites b={b} />
            <SiteSettings className={b('site-settings')} />
          </div> */}
        </div>

        <div className={b('bottom-wrapper')}>
          <p className={b('copyright')}>
            &copy;&nbsp;2024&nbsp;{logoText} {t('copyright')}
          </p>
          <SocialList className={b('social')} items={socialLinks} />
        </div>

      </div>
    </footer>
  );
};
export default PageFooter;
